<template>
  <div>
    <section class="contacts">
        <div class="contacts-block duke-style">
          <h5 style="margin-top: 150px;">Owner's</h5>
            <h1 class="center uppercase gold new" style="margin-top: 0;">Contact</h1>
            <div class="contacts-info-items">
                <a href="tel:+352671204060">
                    <div class="contacts-info-item">
                        <p class="contact-title wht p3">
                            <span class="icon-space">
                                <i class="fal fa-phone-alt "></i>
                            </span>
                            Phone
                        </p>
                        <p class="p1 contact-subtitle">+352 671 204061</p>
                    </div>
                </a>
                <a href="https://wa.me/352671204061">
                    <div class="contacts-info-item">
                        <p class="contact-title wht p3">
                            <span class="icon-space">
                                <i class="fab fa-whatsapp"></i>
                            </span>
                            WHATSAPP / <span class="icon-space">
                                <i class="fab fa-weixin"></i>
                            </span>
                            WECHAT
                        </p>
                        <p class="p1 contact-subtitle">+352 671 204061</p>
                    </div>
                </a>
                <a href="mailto:owner@dukehorlogerie.com">
                    <div class="contacts-info-item">
                        <p class="contact-title wht p3">
                            <span class="icon-space">
                                <i class="fal fa-envelope"></i>
                            </span>
                            Email
                        </p>
                        <p class="p1 contact-subtitle">owner@dukehorlogerie.com</p>
                    </div>
                </a>
                <a href="#">
                    <div class="contacts-info-item">
                        <p class="contact-title wht p3">
                            <span class="icon-space">
                                <i class="fal fa-map-marker-alt"></i>
                            </span>
                            Address
                        </p>
                        <p class="p1 contact-subtitle">Grand Duchy of Luxembourg</p>
                    </div>
                </a>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "Contact",
  data() {
    return {
      isLoading: false,
      userId: ""
    };
  },
  methods: {},
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    }
  },
  created() {}
};
</script>

<style scoped>
.contacts {
  background-image: url("~@/assets/photos/video/duke-video-01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
}
.contacts-block h1 {
  padding-bottom: 200px;
}
.contacts-block {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contacts-info-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  width: 90%;
  padding: 50px;
}
.contacts-info-item {
  border-top: 1px solid #707070;
  padding: 20px 0;
  border-bottom: 1px solid #707070;
  transition-duration: 0.3s;
}
/* .contacts-info-item:hover {
  border-top: 1px solid #cea183;
  border-bottom: 1px solid #cea183;
  transition-duration: 0.3s;
} */
.contacts-info-item:hover {
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  transition-duration: 0.3s;
}
.contacts-info-items a:hover {
  text-decoration: none;
}
/* .contact-title {
  font-size: 20px;
  line-height: 24px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.contact-subtitle {
  font-size: 20px;
  line-height: 24px;
  color: #707070;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
} */
.icon-space {
  padding-right: 5px;
}

@media (max-height: 800px) {
  .contacts {
    height: auto;
    min-height: 100vh;
  }
  .contacts-block h1 {
    padding-bottom: 50px;
  }
}

@media (max-width: 900px) {
  .contacts-info-items {
    grid-template-columns: 1fr;
    width: 100%;
    gap: 50px;
    padding: 50px 15%;
  }
  .contacts-block h1 {
    padding-bottom: 0px;
  }
  .contacts-block {
    height: initial;
  }
  /* .contact-subtitle {
    font-size: 16px;
    line-height: 20px;
  } */
}

@media (max-width: 576px) {
  .contacts-info-items {
    padding: 50px 10%;
  }
}

@media (max-width: 420px) {
  .contacts-info-items {
    padding: 50px 20px;
  }
}</style>
